.MediaGalleryModal {
  min-width: 70vw !important;

	.modal-title {
		max-width: 85%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.modal-body {
		padding: 0 !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		background-color: #242435;
		margin-bottom: 0 !important;
	}

	.previous-button,
	.next-button {
		width: 100px;
		transition: background-color 200ms;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		outline: none;

		&:hover,
		&:active {
			background-color: rgba(white, 0.05);
		}

		svg {
			filter: brightness(0.5);
			transition: transform 200ms;
		}

		@media (max-width: 1000px) {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 50px;
			z-index: 99999;
			opacity: 0;
			width: 50%;


			&:hover,
			&:active {
				background-color: transparent;
			}

			&.previous-button {
				left: 0;
			}

			&.next-button {
				right: 0;
			}

			&>svg {
				height: 34px;
				width: 34px;
			}
		}

		@media (max-width: 768px) {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 50px;
			z-index: 99999;
			opacity: 0;
			width: 50%;
		}
	}

	.previous-button {
		&>svg {
			transform: rotate(180deg);
		}

		&:active>svg {
			transform: rotate(180deg) translateX(2px);
		}
	}

	.next-button {
		&:active>svg {
			transform: translateX(2px);
		}
	}



	.media__ {
		max-height: 90vh;
		max-width: 77vw;
	}

	@media (max-width: 1000px) {
		padding: 12px;
    max-width: 100%;
	}

	&>.overlay-click-zone {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 50;
		cursor: pointer;
	}

	img {
		max-height: calc(100vh - 1em - 150px);
		max-width: 100%;
		width: auto;
		height: auto;
		z-index: 10000;
		position: relative;
		min-width: 100px;
		margin: auto;
		user-select: none;
	}


	.Image {
		transition: opacity 400ms;

		&.loading {
			opacity: 0;
		}
	}
}